<template>
  <v-card class="d-flex flex-row pa-4 mb-6 mx-1 align-center">
    <span class="mr-2">{{$t('t.Number#')}} {{reminder.run.number}}</span>
    <calendar-date
      class="mr-12"
      :date="reminder.run.date"
      show-time
    />
    <reminder-type-ref
      :id="reminder.reminderType.value"
      class="mr-12"
    />
    <div>
      <user-ref
        v-if="reminder.contactId"
        :hide-email="reminder.reminderType.value === '!email'"
        hide-phone
        hide-group
        :id="reminder.contactId"
        offset-y
      />
      <v-icon v-else>{{$icon('i.None')}}</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {
    UserRef: () => import('@/components/documents-ref/user-ref'),
    CalendarDate: () => import('@/components/calendar-date'),
    ReminderTypeRef: () => import('@/components/documents-ref/reminder-type-ref')
  },
  computed: {
  },
  data () {
    return {
    }
  },
  props: {
    reminder: Object
  }
}
</script>

<style lang="stylus" scoped></style>
